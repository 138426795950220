import React from "react"
import Layout from "../components/layout"
import { Container } from "reactstrap"
import Head from "../components/head"
import { Link } from "gatsby"
import LazyLoad from "react-lazyload"
import catImage from "../images/category/101.jpg"
import catNum5Image from "../images/category/71.jpg"
import catNum8Image from "../images/category/87.jpg"
import catNum9Image from "../images/category/69.jpg"
import catNum10Image from "../images/category/58.jpg"
import catNum11Image from "../images/category/70.jpg"
import catNum12Image from "../images/category/77.jpg"
import catNum13Image from "../images/category/80.jpg"

const ThrowYourVoice = () => {
  //   const CatImage = "../images/category/101.jpg"
  const CatName = "Throw Your Voice"

  const CatTagLine = "Ventriloquist Dummies, Puppets, Marionettes and More"

  const CatDesc =
    "Like putting on shows? Want to amuse yourself or friends? Entertain anyone with our various assortments of ventriloquist dummies, hand puppets and marionettes. Accessories, replacement parts for your dummy and DVDs and books to learn ventriloquism also available"

  const categories = [
    {
      catNum: 5,
      categoryName: "Female Ventriloquist Dummies - NEW Characters!",
      categoryTag: "Standard and Deluxe Upgrade Converted Dummies!",
      categoryImage: catNum5Image,
      categoryLink: "/female-ventriloquist-dummies",
    },
    {
      catNum: 12,
      categoryName: "Hand Puppets",
      categoryTag: "Silly Puppets & More",
      categoryImage: catNum12Image,
      categoryLink: "/hand-puppets",
    },
    {
      catNum: 11,
      categoryName: "Accessories & Replacement Stuff",
      categoryTag: "New Things For Your Old Dummy!",
      categoryImage: catNum11Image,
      categoryLink: "/accessories-replacement-stuff",
    },
    {
      catNum: 9,
      categoryName: "Marionettes",
      categoryTag: "Made In Prague",
      categoryImage: catNum9Image,
      categoryLink: "/marionettes",
    },
    {
      catNum: 8,
      categoryName: "Professional Ventriloquist Dummies",
      categoryTag: "Carved Wooden Dummies & More",
      categoryImage: catNum8Image,
      categoryLink: "/professional-ventriloquist-dummies",
    },
    {
      catNum: 10,
      categoryName: "Books, DVDs, Cases & Stands",
      categoryTag: "Learn To Be A Ventriloquist",
      categoryImage: catNum10Image,
      categoryLink: "/books-dvds-cases-and-stands",
    },
    {
      catNum: 13,
      categoryName: "Stretch Ducks",
      categoryTag: "So Unusual Its In A Class Of Is Own",
      categoryImage: catNum13Image,
      categoryLink: "/stretch-ducks",
    },
  ]
  return (
    <Layout>
      <Head
        title="Throw Your Voice Department"
        description="Ventriloquist dummies, puppets, marionettes, accessories, replacement parts for your dummy and DVDs and books to learn ventriloquism from ThrowThings.com"
      />
      <Container>
        {/*
        This modified to above
          const categories = [
    {
      catNum: 1,
      categoryName: "Basic Ventriloquist Dummies",
      categoryTag: "Stuffed Body, Pull String To Make Mouth Move",
      categoryImage: catNum1Image,
      categoryLink: "/basic-ventriloquist-dummies",
    },
    {
      catNum: 2,
      categoryName: "Standard Upgrade Ventriloquist Dummies",
      categoryTag: "Moving Head, Trigger Action Mouth & More",
      categoryImage: catNum2Image,
      categoryLink: "/standard-upgrade-ventriloquist-dummies",
    },
    {
      catNum: 3,
      categoryName: "Deluxe Upgrade Ventriloquist Dummies",
      categoryTag: "Moving Eyes & Head, Many With Lifelike Wig, More",
      categoryImage: catNum3Image,
      categoryLink: "/deluxe-upgrade-ventriloquist-dummies",
    },
    {
      catNum: 4,
      categoryName: "Super Deluxe Upgrade Ventriloquist Dummies",
      categoryTag: "Moving Eyes and Head PLUS Moving Brows!",
      categoryImage: catNum4Image,
      categoryLink: "/super-deluxe-upgrade-ventriloquist-dummies",
    },
    {
      catNum: 5,
      categoryName: "Female Ventriloquist Dummies - NEW Characters!",
      categoryTag: "Standard and Deluxe Upgrade Converted Dummies!",
      categoryImage: catNum5Image,
      categoryLink: "/female-ventriloquist-dummies",
    },
    {
      catNum: 12,
      categoryName: "Hand Puppets",
      categoryTag: "Silly Puppets & More",
      categoryImage: catNum12Image,
      categoryLink: "/hand-puppets",
    },
    {
      catNum: 11,
      categoryName: "Accessories & Replacement Stuff",
      categoryTag: "New Things For Your Old Dummy!",
      categoryImage: catNum11Image,
      categoryLink: "/accessories-replacement-stuff",
    },
    {
      catNum: 9,
      categoryName: "Marionettes",
      categoryTag: "Made In Prague",
      categoryImage: catNum9Image,
      categoryLink: "/marionettes",
    },
    {
      catNum: 6,
      categoryName: "Gramps & Granny",
      categoryTag: "Two New Characters From ThrowThings",
      categoryImage: catNum6Image,
      categoryLink: "/gramps-granny",
    },
    {
      catNum: 8,
      categoryName: "Professional Ventriloquist Dummies",
      categoryTag: "Carved Wooden Dummies & More",
      categoryImage: catNum8Image,
      categoryLink: "/professional-ventriloquist-dummies",
    },
    {
      catNum: 10,
      categoryName: "Books, DVDs, Cases & Stands",
      categoryTag: "Learn To Be A Ventriloquist",
      categoryImage: catNum10Image,
      categoryLink: "/books-dvds-cases-and-stands",
    },
    {
      catNum: 7,
      categoryName: "Jeff Dunham Stuff",
      categoryTag: "Walter, Achmed, Peanur & Little Jeff Stuff",
      categoryImage: catNum7Image,
      categoryLink: "/jeff-dunham-stuff",
    },
    {
      catNum: 13,
      categoryName: "Stretch Ducks",
      categoryTag: "So Unusual Its In A Class Of Is Own",
      categoryImage: catNum13Image,
      categoryLink: "/stretch-ducks",
    },
    {
      catNum: 14,
      categoryName: "Bonus Bundles",
      categoryTag: "Get More Than Just A Dummy!",
      categoryImage: catNum14Image,
      categoryLink: "/bonus-bundles",
    },
    {
      catNum: 15,
      categoryName: "Limited Editions",
      categoryTag: "For The Collector",
      categoryImage: catNum15Image,
      categoryLink: "/limited-editions",
    },
    {
      catNum: 16,
      categoryName: "Upgrade Certificates",
      categoryTag: "Great Gift For Someone With A Basic Ventriloquist Dummy",
      categoryImage: catNum16Image,
      categoryLink: "/upgrade-certificates",
    },
    {
      catNum: 17,
      categoryName: "Puppet-on-a-Stick",
      categoryTag: "Puppet-on-a-Stick, Sea Squad, Puppet-on-a-Pen",
      categoryImage: catNum17Image,
      categoryLink: "/puppet-on-a-stick",
    },
  ]

        */}
        {/* <!-- section start --> */}
        <section className="section-b-space ratio_asos">
          <div className="collection-wrapper">
            <div className="container">
              <div className="row">
                <div className="collection-content col">
                  <div className="page-main-content">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="top-banner-wrapper">
                          {/* <Link to="#"> */}
                          <img
                            src={catImage}
                            className="img-fluid lazyload"
                            alt={CatTagLine}
                          />
                          {/* </Link> */}
                          {/* <Link to="#"><img src={require("../assets/images/mega-menu/2.jpg")} className="img-fluid blur-up lazyload" alt=""/></Link> */}
                          <div className="top-banner-content small-section">
                            <h4>{CatName}</h4>
                            <h5>{CatTagLine}</h5>
                            <p>{CatDesc}</p>
                          </div>
                        </div>
                        <div className="collection-product-wrapper">
                          <div className="product-wrapper-grid">
                            <div className="row">
                              {categories.map(category => (
                                <div
                                  key={category.catNum}
                                  className="col-xl-3 col-md-6 col-grid-box"
                                >
                                  <div className="product-box">
                                    <div className="img-wrapper">
                                      <div className="front">
                                        <Link to={category.categoryLink}>
                                          <LazyLoad>
                                            <img
                                              src={category.categoryImage}
                                              className="img-fluid lazyload bg-img"
                                              alt={category.categoryName}
                                            />
                                          </LazyLoad>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="product-detail">
                                      <div>
                                        <Link to={category.categoryLink}>
                                          <h4>{category.categoryName}</h4>
                                          <h6>{category.categoryTag}</h6>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- section End --> */}
      </Container>
    </Layout>
  )
}

export default ThrowYourVoice
